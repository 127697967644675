/* eslint-disable no-undef */
var pouchLib = {
  /*
		Randomizer
  */
  randomizer: {
    color: function() {
      var color = new RColor()
      var c = color.get(true, 0.3, 0.99)
      return c
    },
    loremIpsum: function(minWordCount, maxWordCount) {
      var loremIpsumWordBank = new Array(
        'lorem',
        'ipsum',
        'dolor',
        'sit',
        'amet,',
        'consectetur',
        'adipisicing',
        'elit,',
        'sed',
        'do',
        'eiusmod',
        'tempor',
        'incididunt',
        'ut',
        'labore',
        'et',
        'dolore',
        'magna',
        'aliqua.',
        'enim',
        'ad',
        'minim',
        'veniam,',
        'quis',
        'nostrud',
        'exercitation',
        'ullamco',
        'laboris',
        'nisi',
        'ut',
        'aliquip',
        'ex',
        'ea',
        'commodo',
        'consequat.',
        'duis',
        'aute',
        'irure',
        'dolor',
        'in',
        'reprehenderit',
        'in',
        'voluptate',
        'velit',
        'esse',
        'cillum',
        'dolore',
        'eu',
        'fugiat',
        'nulla',
        'pariatur.',
        'excepteur',
        'sint',
        'occaecat',
        'cupidatat',
        'non',
        'proident,',
        'sunt',
        'in',
        'culpa',
        'qui',
        'officia',
        'deserunt',
        'mollit',
        'anim',
        'id',
        'est',
        'laborum.',
        'sed',
        'ut',
        'perspiciatis,',
        'unde',
        'omnis',
        'iste',
        'natus',
        'error',
        'sit',
        'voluptatem',
        'accusantium',
        'doloremque',
        'laudantium,',
        'totam',
        'rem',
        'aperiam',
        'eaque',
        'ipsa,',
        'quae',
        'ab',
        'illo',
        'inventore',
        'veritatis',
        'et',
        'quasi',
        'architecto',
        'beatae',
        'vitae',
        'dicta',
        'sunt,',
        'explicabo.',
        'nemo',
        'enim',
        'ipsam',
        'voluptatem,',
        'quia',
        'voluptas',
        'sit,',
        'aspernatur',
        'aut',
        'odit',
        'aut',
        'fugit,',
        'sed',
        'quia',
        'consequuntur',
        'magni',
        'dolores',
        'eos,',
        'qui',
        'ratione',
        'voluptatem',
        'sequi',
        'nesciunt,',
        'neque',
        'porro',
        'quisquam',
        'est,',
        'qui',
        'dolorem',
        'ipsum,',
        'quia',
        'dolor',
        'sit,',
        'amet,',
        'consectetur,',
        'adipisci',
        'velit,',
        'sed',
        'quia',
        'non',
        'numquam',
        'eius',
        'modi',
        'tempora',
        'incidunt,',
        'ut',
        'labore',
        'et',
        'dolore',
        'magnam',
        'aliquam',
        'quaerat',
        'voluptatem.',
        'ut',
        'enim',
        'ad',
        'minima',
        'veniam,',
        'quis',
        'nostrum',
        'exercitationem',
        'ullam',
        'corporis',
        'suscipit',
        'laboriosam,',
        'nisi',
        'ut',
        'aliquid',
        'ex',
        'ea',
        'commodi',
        'consequatur?',
        'quis',
        'autem',
        'vel',
        'eum',
        'iure',
        'reprehenderit,',
        'qui',
        'in',
        'ea',
        'voluptate',
        'velit',
        'esse,',
        'quam',
        'nihil',
        'molestiae',
        'consequatur,',
        'vel',
        'illum,',
        'qui',
        'dolorem',
        'eum',
        'fugiat,',
        'quo',
        'voluptas',
        'nulla',
        'pariatur?',
        'at',
        'vero',
        'eos',
        'et',
        'accusamus',
        'et',
        'iusto',
        'odio',
        'dignissimos',
        'ducimus,',
        'qui',
        'blanditiis',
        'praesentium',
        'voluptatum',
        'deleniti',
        'atque',
        'corrupti,',
        'quos',
        'dolores',
        'et',
        'quas',
        'molestias',
        'excepturi',
        'sint,',
        'obcaecati',
        'cupiditate',
        'non',
        'provident,',
        'similique',
        'sunt',
        'in',
        'culpa,',
        'qui',
        'officia',
        'deserunt',
        'mollitia',
        'animi,',
        'id',
        'est',
        'laborum',
        'et',
        'dolorum',
        'fuga.',
        'harum',
        'quidem',
        'rerum',
        'facilis',
        'est',
        'et',
        'expedita',
        'distinctio.',
        'Nam',
        'libero',
        'tempore,',
        'cum',
        'soluta',
        'nobis',
        'est',
        'eligendi',
        'optio,',
        'cumque',
        'nihil',
        'impedit,',
        'quo',
        'minus',
        'id,',
        'quod',
        'maxime',
        'placeat,',
        'facere',
        'possimus,',
        'omnis',
        'voluptas',
        'assumenda',
        'est,',
        'omnis',
        'dolor',
        'repellendus.',
        'temporibus',
        'autem',
        'quibusdam',
        'aut',
        'officiis',
        'debitis',
        'aut',
        'rerum',
        'necessitatibus',
        'saepe',
        'eveniet,',
        'ut',
        'et',
        'voluptates',
        'repudiandae',
        'sint',
        'molestiae',
        'non',
        'recusandae.',
        'itaque',
        'earum',
        'rerum',
        'hic',
        'tenetur',
        'a',
        'sapiente',
        'delectus,',
        'aut',
        'reiciendis',
        'voluptatibus',
        'maiores',
        'alias',
        'consequatur',
        'aut',
        'perferendis',
        'doloribus',
        'asperiores',
        'repellat'
      )
      var randy =
        Math.floor(Math.random() * (maxWordCount - minWordCount)) + minWordCount
      var ret = ''
      for (var i = 0; i < randy; i++) {
        var newTxt =
          loremIpsumWordBank[
            Math.floor(Math.random() * (loremIpsumWordBank.length - 1))
          ]
        if (
          ret.substring(ret.length - 1, ret.length) === '.' ||
          ret.substring(ret.length - 1, ret.length) === '?'
        ) {
          newTxt =
            newTxt.substring(0, 1).toUpperCase() +
            newTxt.substring(1, newTxt.length)
        }
        ret += ' ' + newTxt
      }
      return 'Lorem ipsum ' + ret.substring(0, ret.length - 1)
    },
    fields: function() {
      $('input').each(function() {
        var input = $(this)
        if (input.attr('type') === 'checkbox') {
          input.attr('checked', 'checked')
        } else if (input.attr('type') === 'text') {
          input.val(pouchLib.randomizer.loremIpsum(1, 5))
        } else if (input.attr('type') === 'email') {
          input.val('pouchjohndoe@yopmail.com')
        } else if (input.attr('type') === 'password') {
          input.val('1234567890')
        }
      })

      $('textarea').each(function() {
        $(this).val(pouchLib.randomizer.loremIpsum(20, 100))
      })
    },
    slug: function() {
      return pouchLib.slug.get(pouchLib.randomizer.loremIpsum(5, 10))
    }
  },
  /*
		Slug

		Used library speakingurl:
		http://pid.github.io/speakingurl/
	*/
  slug: {
    properties: {
      titleCase: false,
      symbols: false,
      lang: 'en',
      truncate: 100
    },
    checkLib: function() {
      if (typeof getSlug === 'undefined') {
        throw new pouchLib.exceptions.MissingLibraryException(
          'speakingurl.min.js'
        )
      }
    },
    get: function(title) {
      pouchLib.slug.checkLib()
      var slug = getSlug(title, pouchLib.slug.properties)
      return slug
    }
  },
  /*
		Mailgun
	*/
  mailgun: {
    key: 'pubkey-3c8064f7b1167546570480b42c283216',
    validate: function(id, inProgressCallback, successCallback, errorCallback) {
      $('#' + id).mailgun_validator({
        api_key: pouchLib.mailgun.key,
        in_progress: inProgressCallback,
        success: function(data) {
          var d = pouchLib.mailgun.getSuggestion(
            data['is_valid'],
            data['did_you_mean']
          )
          successCallback(d)
        },
        error: errorCallback
      })
    },
    getSuggestion: function(isValid, alternate) {
      if (isValid) {
        var result = '<span class="message success">Address is valid.</span>'
        if (alternate) {
          result = ''
          result +=
            '<span class="message warning"> (Though did you mean <em>' +
            alternate +
            '</em>?)</span>'
        }
        return result
      } else if (alternate) {
        return (
          '<span class="message warning">Did you mean <em>' +
          alternate +
          '</em>?</span>'
        )
      } else {
        return '<span class="message error">Address is invalid.</span>'
      }
    },
    checkEmail: function(email, callback) {
      $.ajax({
        url: '/ajax/checkEmail',
        method: 'post',
        data: {
          email: email
        }
      })
        .done(function(res) {
          callback(res)
        })
        .fail(function() {
          console.log('unable to check email validity') //eslint-disable-line
        })
    }
  },
  /*
		Markdown Support
	*/
  markdown: {
    checkLib: function() {
      if (typeof marked === 'undefined') {
        throw new pouchLib.exceptions.MissingLibraryException('marked.js')
      }
    },
    toHTML: function(markdown) {
      pouchLib.markdown.checkLib()
      return marked(markdown)
    },
    editor: function(input, preview) {
      input.keyup(function() {
        var val = input.val()
        preview.html(pouchLib.markdown.toHTML(val))
      })
    }
  },
  /*
		Countdown timer

		You’ll need to set a valid end date. This should be a string in any of the
		formats understood by JavaScript’s Date.parse() method. For example:

		1. The ISO 8601 format:
		var deadline = '2015-12-31 23:59:59 GMT+02:00';

		2. The short format:
		var deadline = '31/12/2015 23:59:59 GMT+02:00';

		3. Or, the long format:
		var deadline = 'December 31 2015 23:59:59 GMT+02:00';
	*/
  countdown: {
    interval: 1000,
    duration: 0,
    create: function(id, endtime, serverTime, callbackUpdating, callbackDone) {
      this.duration = moment.duration(
        (endtime - serverTime) * 1000,
        'milliseconds'
      )
      function updateClock() {
        var t = pouchLib.countdown.getTimeRemaining()

        $('#' + id)
          .find('.days > p')
          .html(t.days)
        $('#' + id)
          .find('.hours > p')
          .html(('0' + t.hours).slice(-2))
        $('#' + id)
          .find('.minutes > p')
          .html(('0' + t.minutes).slice(-2))
        $('#' + id)
          .find('.seconds > p')
          .html(('0' + t.seconds).slice(-2))

        if (t.total <= 0) {
          clearInterval(timeinterval)
          callbackDone()
        } else {
          callbackUpdating(t)
        }
      }

      updateClock()
      var timeinterval = setInterval(updateClock, 1000)
    },
    getTimeRemaining: function() {
      this.duration = moment.duration(
        this.duration - this.interval,
        'milliseconds'
      )
      return {
        total: this.duration,
        days: this.duration.days(),
        hours: this.duration.hours(),
        minutes: this.duration.minutes(),
        seconds: this.duration.seconds()
      }
    }
  },
  /*
		Date Time Library

		This section converts string <-> date <-> moment objects.
		moment objects are the most useful for doing calculations.
		So convert you string/date objects to moment first using
		the provided functions below. After that, see what you can do
		using the moment.js docs:

		http://momentjs.com/docs/
	*/
  dateTime: {
    checkLib: function() {
      if (typeof moment === 'undefined') {
        throw new pouchLib.exceptions.MissingLibraryException('moment.js')
      }
    },
    checkString: function(str) {
      if (!moment(str).isValid()) {
        throw new pouchLib.exceptions.UnknownFormatException(str)
      }
    },
    convertHoursFormat: function(num) {
      if (num > 24 || num < 0) {
        throw new pouchLib.exceptions.PouchLibException('Invalid hour digit')
      }
      if (num >= 12) {
        if (num !== 12) {
          num -= 12
          if (num === 12) {
            return num + ' AM'
          }
        }
        return num + ' PM'
      } else {
        return num + ' AM'
      }
    },
    stringToMoment: function(str) {
      pouchLib.dateTime.checkLib()
      pouchLib.dateTime.checkString(str)
      return moment(str)
    },
    stringToMomentFromFormat: function(str, format) {
      pouchLib.dateTime.checkLib()
      pouchLib.dateTime.checkString(str)
      return moment(str, format)
    },
    dateToMoment: function(date) {
      pouchLib.dateTime.checkLib()
      return moment(date)
    },
    dateToMomentUtc: function(date) {
      pouchLib.dateTime.checkLib()
      return moment(date).utc()
    },
    parse: function(rfc3339Str) {
      pouchLib.dateTime.checkLib()
      return moment(rfc3339Str)
    },
    fromDateToCurrentDateDiff: function(date, format, utc, venue) {
      this.checkLib()
      this.checkString(date)
      var currentDate = utc ? moment.utc() : moment()
      var fromDate = venue
        ? this.dateToMomentUtc(date)
        : this.dateToMoment(date)
      return fromDate.diff(currentDate, format)
    },
    dateToFormat: function(date, format, utc) {
      this.checkLib()
      this.checkString(date)
      momentObj = utc ? this.dateToMomentUtc(date) : this.dateToMoment(date)
      return momentObj.format(format)
    },
    isValidFormat: function(date, format) {
      return moment(date, format).isValid()
    },
    getYears: function() {
      var max = new Date().getFullYear()
      var min = max - 80
      var years = []
      for (var i = max; i > min; i--) {
        years.push(i)
      }
      return years
    },
    getMonths: function() {
      var count = 0
      var months = []
      while (count < 12)
        months.push(
          moment()
            .month(count++)
            .format('MMMM')
        )
      return months
    },
    getDays: function(month, year) {
      var days = []
      var yearFormat =
        year +
        '-' +
        moment()
          .month(month)
          .format('MM')
      var count = moment(yearFormat, 'YYYY-MM').daysInMonth()
      for (var i = 1; i <= count; i++) {
        days.push(i)
      }
      return days
    },
    getYearHTML: function() {
      var html =
        '' +
        '<div class="ui search selection fluid dropdown year corner labeled" id="fieldYear">' +
        '<input type="hidden" name="fieldYear">' +
        '<i class="dropdown icon"></i>' +
        '<div class="default text">Select Year</div>' +
        '<div class="ui corner label">' +
        '<i class="asterisk icon"></i>' +
        '</div>' +
        '<div class="menu">'

      var years = pouchLib.dateTime.getYears()
      for (var i = 0; i < years.length; i++) {
        html =
          html +
          '<div class="item" data-value="' +
          years[i] +
          '">' +
          years[i] +
          '</div>'
      }

      html = html + '</div>' + '</div>'
      return html
    },
    getMonthHTML: function() {
      var html =
        '' +
        '<div class="ui search selection fluid dropdown month corner labeled" id="fieldMonth">' +
        '<input type="hidden" name="fieldMonth">' +
        '<i class="dropdown icon"></i>' +
        '<div class="default text">Select Month</div>' +
        '<div class="ui corner label">' +
        '<i class="asterisk icon"></i>' +
        '</div>' +
        '<div class="menu">'

      var months = pouchLib.dateTime.getMonths()
      for (var i = 0; i < months.length; i++) {
        html =
          html +
          '<div class="item" data-value="' +
          (i + 1) +
          '">' +
          months[i] +
          '</div>'
      }

      html = html + '</div>' + '</div>'
      return html
    },
    getDayHTML: function() {
      var html =
        '' +
        '<div class="ui search selection fluid dropdown day corner labeled" id="fieldDay">' +
        '<input type="hidden" name="fieldDay">' +
        '<i class="dropdown icon"></i>' +
        '<div class="default text">Select Day</div>' +
        '<div class="ui corner label">' +
        '<i class="asterisk icon"></i>' +
        '</div>' +
        '<div class="menu"></div>' +
        '</div>'
      return html
    },
    getDayOptions: function(month, year) {
      var html = ''
      var days = pouchLib.dateTime.getDays(month, year)
      for (var i = 0; i < days.length; i++) {
        html =
          html +
          '<div class="item" data-value="' +
          (days[i] + 1) +
          '">' +
          (days[i] + 1) +
          '</div>'
      }
      return html
    },
    toDateField: function(selector) {
      var element = $(selector)
      var html =
        '' +
        '<div class="three fields">' +
        '<div class="field req">' +
        '<label>Year</label>' +
        pouchLib.dateTime.getYearHTML() +
        '</div>' +
        '<div class="field req">' +
        '<label>Month</label>' +
        pouchLib.dateTime.getMonthHTML() +
        '</div>' +
        '<div class="field req">' +
        '<label>Day</label>' +
        pouchLib.dateTime.getDayHTML() +
        '</div>' +
        '</div>'

      element.html(html)
    },
    getDateFieldValue: function(selector) {
      var month = $(selector + ' .ui.dropdown.month').dropdown('get value')
      var year = $(selector + ' .ui.dropdown.year').dropdown('get value')
      var day = $(selector + ' .ui.dropdown.day').dropdown('get value')
      var c = month + '-' + day + '-' + year
      return c
    }
  },
  /*
		TODO: NEED OPENEXCHANGEAPI KEYS (NOT WORKING)
		Currency Library

		This section handles currency converter and uses money.js as a dependency.
		For improvements, see money.js docs:

		http://openexchangerates.github.io/money.js/
	*/
  currency: {
    checkLib: function() {
      if (typeof fx === 'undefined') {
        throw new pouchLib.exceptions.MissingLibraryException('money.js')
      }
    },
    convert: function(value, from, to) {
      pouchLib.currency.checkLib()
      return fx.convert(value, { from: from, to: to })
    },
    format: function(amount, currency) {
      currency = pouchLib.string.capitalize(currency)
      return currency + ' ' + (amount * 1).formatMoney(2, '.', ',')
    },
    formatNoCurrency: function(amount) {
      return '' + (amount * 1).formatMoney(2, '.', ',')
    },
    patterns: {
      visa: /^4/, //visa starts with 4
      mastercard: /^5[1-5]/, //mastercard numbers start with the numbers 51 through 55
      amex: /^5[1-5]/, //American Express card numbers start with 34 or 37
      discover: /^6(?:011|5[0-9]{2})/ //Discover card numbers begin with 6011 or 65
    },
    detectCard: function(cc) {
      cc = cc.replace(/\s+/g, '')
      if (pouchLib.currency.patterns.visa.test(cc)) {
        return 'visa'
      } else if (pouchLib.currency.patterns.mastercard.test(cc)) {
        return 'mastercard'
      } else if (pouchLib.currency.patterns.amex.test(cc)) {
        return 'amex'
      } else if (pouchLib.currency.patterns.discover.test(cc)) {
        return 'discover'
      } else {
        return 'unsupported'
      }
    }
  },
  /*
		Location Library

		Usage:

		1. Getting position

		pouchLib.location.getPosition(function(position){
			console.log("Latitude: ");
			console.log(position.coords.latitude);

			console.log("Longitude: ");
			console.log(position.coords.longitude);
		});

		2. Getting position and displaying on map

		<div id="map"></div>

		pouchLib.location.getPosition(function(position){
			var options = pouchLib.location.defaultOptions();
			options.zoom = 13;

			pouchLib.location.locationToMap("map", position, options);
		});

	*/
  location: {
    checkLib: function() {
      if (!navigator.geolocation) {
        throw new pouchLib.exceptions.UnsupportedFeatureException('Geolocation')
      }
      if (typeof google === 'undefined') {
        // throw new pouchLib.exceptions.MissingLibraryException("http://maps.google.com/maps/api/js?sensor=false");
      }
    },
    radius: function(x) {
      return x * Math.PI / 180
    },
    getPosition: function(callback, errorCallback) {
      pouchLib.location.checkLib()
      navigator.geolocation.getCurrentPosition(callback, errorCallback)
    },
    error: function(msg) {
      throw new pouchLib.exceptions.PouchLibException(
        'Failed with error message: ' + msg
      )
    },
    defaultOptions: function() {
      var options = {
        zoom: 15,
        mapTypeControl: false,
        navigationControlOptions: {
          style: google.maps.NavigationControlStyle.SMALL
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      return options
    },
    locationToMap: function(elementId, position, options) {
      var latlng = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      )
      options.center = latlng

      var map = new google.maps.Map(document.getElementById(elementId), options)

      var marker = new google.maps.Marker({ //eslint-disable-line
        position: latlng,
        map: map,
        title:
          'You are here! (at least within a ' +
          position.coords.accuracy +
          ' meter radius)'
      })
    },
    compute: function(lat1, lng1, lat2, lng2) {
      var R = 6378137 // Earth’s mean radius in meter
      var dLat = pouchLib.location.radius(lat2 - lat1)
      var dLong = pouchLib.location.radius(lng2 - lng1)

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(pouchLib.location.radius(lat1)) *
          Math.cos(pouchLib.location.radius(lat2)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      var d = R * c
      return d // returns the distance in meter
    },
    getDirections: function(lat, lng) {
      pouchLib.location.checkLib()
      window.open(
        'https://maps.google.fr/maps?daddr=' + lat + ',' + lng,
        '_blank'
      )
    }
  },
  /*
		MIXPANEL

		1. If session exists, set user (make sure you have id property on your json object):

		pouchLib.mixpanel.setUser(jsonUserProperties);

		2. Then go crazy! TRACK EVERYTHING! using:

		pouchLib.mixpanel.trackAll();
	*/
  mixpanel: {
    token: '463bf18fd71bf28fa2fb16aaf5033b8a',
    tokenDev: '9c28cd755a9432bebaea1cd2c788750c',
    tokenProd: 'bd576e1868f0cba684e1568ff9be6e25',
    checkLib: function(callback) {
      if (typeof mixpanel === 'undefined') {
        $.ajax({
          method: 'GET',
          url: '/ajax/env'
        }).done(function(data) {
          if (data !== 'office_local') {
            throw new pouchLib.exceptions.MissingLibraryException('mixpanel')
          }
        })
      } else {
        callback()
      }
    },
    setUserId: function(id) {
      pouchLib.mixpanel.checkLib(function() {
        mixpanel.identify(id)
      })
    },
    setUser: function(properties) {
      pouchLib.mixpanel.checkLib(function() {
        if (
          typeof properties !== 'undefined' &&
          properties !== null &&
          typeof properties.id !== 'undefined'
        ) {
          properties = pouchLib.mixpanel.setSpecialProperties(properties)
          pouchLib.mixpanel.setUserId(properties.id)
          mixpanel.people.set(properties)
          // mixpanel.alias(properties.id);
        } else {
          throw new pouchLib.exceptions.PouchLibException(
            'Missing property `id` in mixpanel.setUser() '
          )
        }
      })
    },
    setSpecialProperties: function(properties) {
      properties = {}
      properties.$name = properties.firstName + ' ' + properties.lastName
      properties.$first_name = properties.firstName
      properties.$last_name = properties.lastName
      properties.$email = properties.email
      properties.$phone = properties.mobileNumber
      return properties
    },
    setSessionProperty: function(key) {
      pouchLib.mixpanel.checkLib(function() {
        var json = {}
        json[key] = val
        mixpanel.register(json)
      })
    },
    unsetSessionProperty: function(key) {
      pouchLib.mixpanel.checkLib(function() {
        mixpanel.unregister(key)
      })
    },
    startTrackDuration: function(event) {
      pouchLib.mixpanel.checkLib(function() {
        mixpanel.time_event(event)
      })
    },
    endTrackDuration: function(event, properties) {
      pouchLib.mixpanel.checkLib(function() {
        mixpanel.track(event, properties)
      })
    },
    track: function(title, properties) {
      pouchLib.mixpanel.checkLib(function() {
        mixpanel.track(title, properties)
      })
    },
    trackLink: function(id) {
      pouchLib.mixpanel.checkLib(function() {
        var element = $('#' + id)
        element.click(function() {
          pouchLib.mixpanel.track('Clicked ' + id + ' Link', {})
        })
        // mixpanel.track_links("#" + id, "Clicked " + id + " Link",{
        // "referrer": document.referrer
        // });
      })
    },
    trackButton: function(id) {
      pouchLib.mixpanel.checkLib(function() {
        var element = $('#' + id)
        element.click(function() {
          pouchLib.mixpanel.track('Clicked ' + id + ' Button', {})
        })
      })
    },
    trackTextBox: function(element) {
      pouchLib.mixpanel.checkLib(function() {
        element.change(function() {
          pouchLib.mixpanel.track(
            'Changed ' + element.attr('id') + ' Textbox',
            {
              value: element.val()
            }
          )
        })
      })
    },
    trackDropdown: function(element) {
      pouchLib.mixpanel.checkLib(function() {
        element.change(function() {
          pouchLib.mixpanel.track(
            'Changed ' + element.attr('id') + ' Dropdown',
            {
              value: element.val()
            }
          )
        })
      })
    },
    trackAll: function() {
      pouchLib.mixpanel.checkLib(function() {
        // Unbind click events for link to prevent duplicate tracking (settings page)
        // $("a").off("click");
        //track all anchor clicks that have an ID
        $('a').each(function() {
          var element = this
          if (typeof $(element).attr('id') !== 'undefined') {
            pouchLib.mixpanel.trackLink($(element).attr('id'))
          }
        })
        //track all button clicks that have an ID
        $('button').each(function() {
          var element = this
          if (typeof $(element).attr('id') !== 'undefined') {
            pouchLib.mixpanel.trackButton($(element).attr('id'))
          }
        })
        $("input[type='submit']").each(function() {
          if (typeof $(this).attr('id') !== 'undefined') {
            pouchLib.mixpanel.trackButton($(this).attr('id'))
          }
        })
        //track all textbox changes that have an ID
        $("input[type='text']").each(function() {
          if (typeof $(this).attr('id') !== 'undefined') {
            pouchLib.mixpanel.trackTextBox($(this))
          }
        })
        $("input[type='password']").each(function() {
          if (typeof $(this).attr('id') !== 'undefined') {
            pouchLib.mixpanel.trackTextBox($(this))
          }
        })
        $("input[type='date']").each(function() {
          if (typeof $(this).attr('id') !== 'undefined') {
            pouchLib.mixpanel.trackTextBox($(this))
          }
        })
        //track all dropdown changes that have an ID
        $('select').each(function() {
          if (typeof $(this).attr('id') !== 'undefined') {
            pouchLib.mixpanel.trackDropdown($(this))
          }
        })
        //track div elements with onclick event
        $('div[id][ng-click]').each(function() {
          pouchLib.mixpanel.trackButton($(this).attr('id'))
        })
        $('div[id][onclick]').each(function() {
          pouchLib.mixpanel.trackButton($(this).attr('id'))
        })
        //track span elements with onclick event
        $('span[id][ngclick]').each(function() {
          pouchLib.mixpanel.trackButton($(this).attr('id'))
        })
      })
    },
    trackReact: function() {
      pouchLib.mixpanel.checkLib(function() {
        $('a').each(function() {
          if (
            typeof $(this).attr('data-reactid') !== 'undefined' &&
            typeof $(this).attr('id') !== 'undefined'
          ) {
            pouchLib.mixpanel.trackLink($(this).attr('id'))
          }
        })
        //track all button clicks that have an ID
        $('button').each(function() {
          if (
            typeof $(this).attr('data-reactid') !== 'undefined' &&
            typeof $(this).attr('id') !== 'undefined'
          ) {
            pouchLib.mixpanel.trackButton($(this).attr('id'))
          }
        })
        $("input[type='submit']").each(function() {
          if (
            typeof $(this).attr('data-reactid') !== 'undefined' &&
            typeof $(this).attr('id') !== 'undefined'
          ) {
            pouchLib.mixpanel.trackButton($(this).attr('id'))
          }
        })
        //track all textbox changes that have an ID
        $("input[type='text']").each(function() {
          if (
            typeof $(this).attr('data-reactid') !== 'undefined' &&
            typeof $(this).attr('id') !== 'undefined'
          ) {
            pouchLib.mixpanel.trackTextBox($(this))
          }
        })
        $("input[type='password']").each(function() {
          if (
            typeof $(this).attr('data-reactid') !== 'undefined' &&
            typeof $(this).attr('id') !== 'undefined'
          ) {
            pouchLib.mixpanel.trackTextBox($(this))
          }
        })
      })
    },
    trackPageVisit: function(page) {
      pouchLib.mixpanel.checkLib(function() {
        pouchLib.mixpanel.track('Visited ' + page + ' Page', {})
      })
    }
  },
  number: {
    checkIfStringIsANumber: function(num) {
      return /^\d+$/.test(num)
    },
    isValidMobileNumber: function(num) {
      if (num) {
        return num.toString().length >= 7 && /^[\d+ ]+$/.test(num)
      }
      return false
    },
    getRandomNumber: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    isInt: function(num) {
      return num % 1 === 0
    }
  },
  /*
		Service Workers.
		Highly advanced, so read more about it before modifying codes below.

		Useful links for debugging:
		chrome://inspect/#service-workers
	*/
  sw: {
    register: function() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('/sw.js')
          .then(function(registration) {
            // Registration was successful
            console.log( //eslint-disable-line
              'ServiceWorker registration successful with scope: ',
              registration.scope
            )
          })
          .catch(function(err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ', err) //eslint-disable-line
          })
      } else {
        throw new pouchLib.exceptions.UnsupportedFeatureException(
          'Service Workers'
        )
      }
    }
  },
  /*
		Exceptions.

		Add your own custom exception below.
	*/
  exceptions: {
    PouchLibException: function(message) {
      this.message = message
      this.name = 'PouchLibException'
    },
    MissingLibraryException: function(library) {
      this.message = 'Missing library: ' + library
      this.name = 'MissingLibraryException'
    },
    UnknownFormatException: function(str) {
      this.message = 'Provided object has an unknown format: ' + str
      this.name = 'UnknownFormatException'
    },
    MomentObjectRequired: function() {
      this.message = 'Please provide a `moment` object type.'
      this.name = 'MomentObjectRequired'
    },
    UnsupportedFeatureException: function(feature) {
      this.message = 'Feature unsupported: ' + feature
      this.name = 'UnsupportedFeatureException'
    },
    UnknownTicketStatus: function(ids) {
      this.message =
        'Cannot Determine Ticket Status: ' +
        'userId = ' +
        ids.userId +
        ' | ' +
        'purchasedById = ' +
        ids.purchasedById +
        ' | ' +
        'assignedToId = ' +
        ids.assignedToId
      this.name = 'UnknownTicketStatus'
    },
    UnknownChartDataType: function(type) {
      this.message = 'Provided Chart Data Type is invalid: ' + type
      this.name = 'UnknownChartDataType'
    }
  },
  /*
		String
	*/
  string: {
    checkIfString: function(str) {
      if (typeof str !== 'string') {
        throw new pouchLib.exceptions.UnknownFormatException(
          str + ' is not a valid string'
        )
      }
    },
    pad: function(length, number) {
      var pad = ''
      number = '' + number
      for (var i = 0; i < length; i++) {
        pad += '0'
      }
      return ' ' + pad.substring(0, pad.length - number.length) + number
    },
    capitalize: function(string) {
      this.checkIfString(string)
      return string.toUpperCase()
    },
    capitalizeFirstLetter: function(string) {
      this.checkIfString(string)
      var str = string.toLowerCase()
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    isEmail: function(str) {
      //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/
      return re.test(str)
    },
    prettyStatus: function(status) {
      status = pouchLib.string.capitalizeFirstLetter(status)
      if (
        status === 'Inprogress' ||
        status === 'In progress' ||
        status === 'InProgress'
      ) {
        return 'In Progress'
      } else if (
        status === 'Fillingup' ||
        status === 'Filling up' ||
        status === 'FillingUp'
      ) {
        return 'Filling Up'
      } else if (
        status === 'Forconfirmation' ||
        status === 'For confirmation'
      ) {
        return 'For Confirmation'
      } else {
        return status
      }
    },
    statusColor: function(status) {
      status = pouchLib.string.capitalizeFirstLetter(status.replace(' ', ''))
      switch (status) {
        case 'Success':
          return '#77DD77'
        case 'Inprogress':
          return '#FFB347'
        case 'Forconfirmation':
          return '#779ECB'
        case 'Expired':
          return '#FF6961'
        case 'Pending':
          return '#FFB347'
        case 'Cancelled':
          return '#836953'
        case 'Fillingup':
          return '#CFCFC4'
      }
    }
  },
  /*
		URL
	*/
  url: {
    removeDuplicateSlashes: function(url) {
      return url.replace(/([^:]\/)\/+/g, '$1')
    }
  },

  property: {
    exists: function(p) {
      return typeof p !== 'undefined' && p !== null
    }
  },

  status: {
    pretty: function(status) {
      var newStatusSplit = []
      var statusSplit = status.split(' ')
      for (var i in statusSplit) {
        var str = statusSplit[i]
        newStatusSplit.push(pouchLib.string.capitalizeFirstLetter(str))
      }
      return newStatusSplit.join(' ')
    },
    class: function(status) {
      var s = status.toLowerCase()
      return s.replace(' ', '')
    }
  }
}

Number.prototype.formatMoney = function(c, d, t) {
  var n = this,
    x = isNaN((c = Math.abs(c))) ? 2 : c,
    y = d === undefined ? '.' : d,
    z = t === undefined ? ',' : t,
    s = n < 0 ? '-' : '',
    i = parseInt((n = Math.abs(+n || 0).toFixed(x))) + '',
    j = (j = i.length) > 3 ? j % 3 : 0
  return (
    s +
    (j ? i.substr(0, j) + z : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + z) +
    (x
      ? y +
        Math.abs(n - i)
          .toFixed(x)
          .slice(2)
      : '')
  )
}
