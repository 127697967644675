/* eslint-disable no-undef */
var isUnloading = false //eslint-disable-line
$(document).ready(function() {
  window.onbeforeunload = function() {
    isUnloading = true
    $('.ui.modal').hide()
  }
  //hack for back button bug on safari browsers
  window.addEventListener(
    'pageshow',
    function(evt) {
      if (evt.persisted) {
        setTimeout(function() {
          window.location.reload()
        }, 10)
      }
    },
    false
  )

  // Refresh session every 10 mins
  window.setInterval(function() {
    $.ajax({
      cache: false,
      type: 'GET',
      url: '/ajax/session/refresh',
      success: function() {}
    })
  }, 600000)

  setTimeout(function() {
    $('.ui.dropdown:not(.checkin)').dropdown()
  })

  $('.ui.accordion').accordion({ exclusive: false })

  var menuFullBorder = $('.main > .border')
  var menuPadding = $('.main > .padding')
  $(this).scroll(function() {
    var $this = $(this)
    var scrollValue = $this.scrollTop()
    if (scrollValue > 0) {
      menuFullBorder.addClass('show-border')
      menuPadding.addClass('hide-padding')
    } else {
      menuFullBorder.removeClass('show-border')
      menuPadding.removeClass('hide-padding')
    }
    //padding.css('height',parseInt(paddingHeight) - scrollValue);
  })

  $('#ChoosePayment').popup({
    position: 'bottom center',
    inline: true,
    hoverable: true
  })

  $('#TicketStatusBars')
    .children()
    .popup({
      on: 'hover',
      position: 'top center'
    })

  //Guest Table Modal
  $('#GuestTable > tbody > tr').on('click', function() {
    $('.small.modal')
      .modal({
        blurring: true,
        duration: 100
      })
      .modal('show')
  })

  $('.ui.modal').modal({
    allowMultiple: false,
    duration: 100
  })

  $('.ui.modal.-login').modal('attach events', '.ui.modal .showlogin')

  $('.ui.modal.-signup').modal('attach events', '.ui.modal .showsignup')

  $('.ui.modal.-resetpassword').modal(
    'attach events',
    '.ui.modal .showresetpassword'
  )

  //Login / Register Button
  $('.login-register').click(function() {
    $('.ui.modal.-signup')
      .modal({
        blurring: true,
        duration: 100
      })
      .modal('show')
  })

  //Login button below tickets (slug page)
  $('#LoginLink').click(function() {
    $('.ui.modal.-login')
      .modal({
        blurring: true,
        duration: 100
      })
      .modal('show')
  })

  //Show Mobile Menu
  $('.header-top-menu > .loggedinuser > .burgermenu').click(function() {
    $('.header-side-menu').sidebar('toggle')
  })

  //Hide Mobile Menu when chaning resizing screen
  /*$(window).resize(function(e){
        if(e.target.innerWidth > 650) {
            $('.header-side-menu').sidebar('hide');
        }
    });*/

  //Event Tickets
  $('.event-tickets:not(.no-events)').accordion({
    exclusive: false,

    onOpening: function() {
      if (
        $(this)
          .parent()
          .is(':last-child') &&
        !$('.ticketsbooking > .bookingcode').length
      ) {
        $('.event-tickets')
          .children('.ticket:nth-last-child(1)')
          .children('.title')
          .addClass('bottomless')
      }
    },
    onOpen: function() {
      if (
        $(this)
          .parent()
          .is(':last-child') &&
        !$('.ticketsbooking > .bookingcode').length
      ) {
        if (
          !$('.event-tickets')
            .children('.ticket:nth-last-child(1)')
            .children('.title')
            .next('.content.active').length
        ) {
          $('.event-tickets')
            .children('.ticket:nth-last-child(1)')
            .children('.title')
            .removeClass('bottomless')
        }
      }
    },
    onClose: function() {
      if (
        $(this)
          .parent()
          .is(':last-child') &&
        !$('.ticketsbooking > .bookingcode').length
      ) {
        $('.event-tickets')
          .children('.ticket:nth-last-child(1)')
          .children('.title')
          .removeClass('bottomless')
      }
    }
  })

  //End of Event Tickets

  //MIXPANEL
  pouchLib.mixpanel.trackAll()

  $('#GlobalSignInButton').click(function() {
    //pouchLib.mixpanel.startTrackDuration("Signed In");
  })

  /*Upload Receipt Button*/
  $('#UploadReceipt').click(function() {
    var chooseFile = $('#ChooseFileReceipt')
    chooseFile.click()
    chooseFile.change(function() {
      $(this)
        .siblings('.choose-file-label')
        .text($(this)[0].files[0].name)
    })
  })

  /* Set Locale */

  /// change site language via dropdown selection
  $('.ui.dropdown.translation > .menu > .item').each(function() {
    $(this).click(function(e) {
      var language = $(this).attr('data-value')
      $.ajax({
        cache: false,
        type: 'GET',
        url: '/ajax/locale/set',
        data: {
          locale: language
        },
        success: function() {
          if (window.localStorage) {
            localStorage['site-language'] = language
          }
          window.location.reload()
        }
      })
      e.stopPropagation()
    })
  })

  var browser_lang =
    (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
    navigator.language || // All browsers
    navigator.userLanguage // IE <= 10
  var lang = ''

  if (browser_lang === 'in' || browser_lang === 'id') {
    lang = 'in_ID'
  } else if (browser_lang === 'vi' || browser_lang === 'vi-VN') {
    lang = 'vi_VN'
  } else if (browser_lang === 'th' || browser_lang === 'th-TH') {
    lang = 'th_THA'
  } else {
    lang = 'en_US'
  }

  if (window.localStorage) {
    try {
      if (!window.localStorage['firstLoad']) {
        window.localStorage['firstLoad'] = true
        window.localStorage['site-language'] = lang
        change_lang(lang)
      }
    } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem
      Storage.prototype.setItem = function() {}
      //alert('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.');
    }
  }

  function change_lang(ctry) {
    $.ajax({
      cache: false,
      type: 'GET',
      url: '/ajax/locale/set',
      data: {
        locale: ctry
      },
      success: function() {
        window.location.reload()
      }
    })
  }

  /// download tickets
  //$(document).on('click', '#download-link', function(){
  // get ticket count
  //var url = $(this).attr('data-link');
  //var val = $(this).attr('data-count');
  //});

  /// prevent empty links
  $('a[href="#"]').click(function(e) {
    e.preventDefault()
  })
})

// $(window).on('resize', function() {
//   if (window.location.href.indexOf('profile') > -1) {
//     window.location.reload()
//   }
// })
